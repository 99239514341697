import supplierRecallService from '../../../../../services/supplier/supplier-recall'
import {PRODUCT_DISRUPTION_TYPES } from '../../../../../misc/commons'
export default {
    props: ['communicationType'],
    data() {
        return {
            loadingInitial: true,
            mode: '',
            coveredListSearchQuery: {},
            locationsCoveredList: [],
            totalLocationsCovered: 0,
            locationsEmailedList: [],
            totalLocationsEmailed: 0,
            notCoveredListSearchQuery: {},
            locationsNotCoveredList: [],
            totalLocationsNotCovered: 0,
            unableToProcessedSearchQuery: {},
            locationsUnableToProcessedList: [],
            totalLocationsUnableToProcessed: 0,
            isConfirm: false,
            communicationId: null,
            fileId: null,
            locationHeader: [
                {
                    label: '',
                    name: 'select',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'pl-2'
                },
                {
                    label: 'IDN/Provider Name',
                    name: 'idn',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Id',
                    name: 'location_id',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Name',
                    name: 'location_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Address',
                    name: 'address1',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                }
            ],

            unableProcessedLocationHeader: [
                {
                    label: 'IDN/Provider Name',
                    name: 'idn',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Id',
                    name: 'location_id',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Name',
                    name: 'location_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Address',
                    name: 'address1',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                }
            ],
        }
    },
    computed: {
    },

    created() {

    },
    methods: {
        forceRerender(mode,communicationId, fileId) {
            this.mode = mode
            this.loadingInitial = true
            this.communicationId = communicationId
            this.fileId = fileId
            this.$refs.modal.show()
            this.loadingInitial = false
            if(this.mode == 'selectAll')
            {
                this.fetchAllLocationList()
            }
        },
        onConfirm() {
            this.$refs.modal.hide()
            this.updateLocationStatus(true)
            this.$emit('onCompleted')
            this.$parent.fetchNotCoveredList()
            this.$parent.fetchEmailedList()
            this.$parent.fetchCoveredList()
            this.$parent.fetchUnRecognisedList()
        },
        onClose() {
            this.updateLocationStatus(false)
            this.$emit('onCompleted')
            this.$refs.modal.hide()
        },
        updateLocationStatus(isConfirm) {
            supplierRecallService.updateLocationStatus(this.communicationId, isConfirm).then(resp => {
                if (!resp.error) {
                    this.$parent.fetchNotCoveredList()
                    this.$parent.fetchEmailedList()
                    this.$parent.fetchCoveredList()
                    this.$parent.fetchUnRecognisedList()
                }
            })
        },
        fetchAllLocationList() {
            supplierRecallService.locationsBycommunicationId({
                ...this.coveredListSearchQuery,
                communicationId: this.communicationId
            }).then(resp => {
                this.fetchCoveredList()
                this.fetchEmailedList()
                this.fetchUnableToProcessedList()
                this.fetchNotCoveredList()
            })
        },
        fetchCoveredList() {
            supplierRecallService.findLocationsBycommunicationIdAndFileId({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null,
                sortDirection: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                fileId: this.fileId,
                type: 'COVERED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsCoveredList = resp.data.d.c
                    this.totalLocationsCovered = resp.data.d.t
                }
            })
        },

        fetchNotCoveredList() {
            supplierRecallService.findLocationsBycommunicationIdAndFileId({
                ...this.notCoveredListSearchQuery,
                sortField: this.notCoveredListSearchQuery.sort[0] ? this.notCoveredListSearchQuery.sort[0].name : null,
                sortDirection: this.notCoveredListSearchQuery.sort[0] ? this.notCoveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                fileId: this.fileId,
                type: 'NON_COVERED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsNotCoveredList = resp.data.d.c
                    this.totalLocationsNotCovered = resp.data.d.t
                }
            })

        },
        fetchEmailedList() {
            supplierRecallService.findLocationsBycommunicationIdAndFileId({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null,
                sortDirection: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                fileId: this.fileId,
                type: 'EMAILED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsEmailedList = resp.data.d.c
                    this.totalLocationsEmailed = resp.data.d.t
                }
            })
        },
        fetchUnableToProcessedList() {
            supplierRecallService.findLocationsBycommunicationIdAndFileId({
                ...this.unableToProcessedSearchQuery,
                sortField: this.unableToProcessedSearchQuery.sort[0] ? this.unableToProcessedSearchQuery.sort[0].name : null,
                sortDirection: this.unableToProcessedSearchQuery.sort[0] ? this.unableToProcessedSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                fileId: this.fileId,
                type: 'UNABLE_PROCESSED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsUnableToProcessedList = resp.data.d.c
                    this.totalLocationsUnableToProcessed = resp.data.d.t
                }
            })
        },

        downloadFile(type) {
            supplierRecallService.downloadAffectedLocations({
                communicationId: this.communicationId,
                fileId: this.fileId,
                type
            }).then(resp => {
                if (!resp.error) {
                    window.open(resp.data.d, '_blank')
                }
            })
        }
    }
}